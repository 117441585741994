@use "@angular/material" as mat;
@import "@angular/material/theming";

@mixin custom-material-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .ng-bar {
    background-color: mat.get-color-from-palette($accent) !important;

    .ng-meteor {
      box-shadow: mat.get-color-from-palette($accent) 0px 0px 10px,
        mat.get-color-from-palette($accent) 0px 0px 5px !important;
    }
  }

  .super-item {
    background-color: rgba(#52ff57, 0.5) !important;
  }

  a {
    color: mat.get-color-from-palette($primary);
  }

  .mat-tab-header {
    margin-bottom: 0.5rem;

    .mat-tab-link {
      text-decoration: none;
    }

    .mat-tab-list {
      .mat-ink-bar {
        background-color: mat.get-color-from-palette($primary);
      }
    }
  }

  .mat-expansion-panel {
    box-shadow: 0 3px 6px 0 rgba(black, 0.02) !important;
  }

  .mat-card {
    margin-bottom: 1.5rem;
    box-shadow: 0 3px 6px 0 rgba(black, 0.02) !important;

    .mat-card-header {
      position: relative;

      .mat-card-title {
        display: flex;
        align-items: center;
      }

      .mat-card-header-text {
        margin: 0;
      }

      .mat-card-options {
        position: absolute;
        top: 0;
        right: 0;
      }

      .top-right-action {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }

  .introduction {
    background-color: darken(
      mat.get-color-from-palette($background, background),
      2%
    );
  }

  .sheet {
    border-radius: 5px;
    padding: 1rem;
    margin-bottom: 1.5rem;
    background-color: mat.get-color-from-palette($background, card);
    box-shadow: 0 3px 6px 0 rgba(black, 0.02);
    transition: box-shadow 0.3s ease-in-out;

    &.glass {
      background-color: rgba(white, 0.8);
      backdrop-filter: blur(5px);
      border-radius: 2rem;
    }

    &:hover {
      box-shadow: 0 3px 6px 0 rgba(black, 0.1);
    }
  }

  .table-wrapper {
    width: 100%;
    overflow-x: auto;

    td {
      .shortcut-button {
        opacity: 1;
        transition: 0.2s ease all;
      }

      &:hover {
        .shortcut-button {
          opacity: 1;
        }
      }
    }
  }

  .mat-form-field-custom {
    .mat-form-field-wrapper {
      padding-bottom: 1.25rem;
    }

    .label {
      display: block;
      color: mat.get-color-from-palette($foreground, base, 0.6);
      font-size: 0.75rem;
      line-height: 1;
      margin-bottom: 0.4rem;
    }

    .input {
      background-color: mat.get-color-from-palette($background, app-bar);
      border-radius: 5px;
      overflow: hidden;
    }

    .editor {
      background-color: mat.get-color-from-palette($background, app-bar);
      border-radius: 5px;
      padding: 1rem;
      overflow: hidden;

      image-cropper {
        padding: 0;
      }
    }

    .image {
      background-color: mat.get-color-from-palette($background, app-bar);
      border-radius: 5px;
      padding: 1rem;
      overflow: hidden;
      text-align: center;

      img {
        max-width: 90%;
        max-height: 200px;
      }
    }

    .map {
      border-radius: 5px;
      overflow: hidden;
      height: 300px;
    }

    .qrcode {
      background-color: mat.get-color-from-palette($background, background);
      border-radius: 5px;
      padding: 1rem;
      overflow: hidden;
      text-align: center;

      img {
        max-width: 100%;
        width: 200px;
        height: auto;
        box-shadow: 0 3px 6px 0 rgba(black, 0.1);
        border-radius: 5px;
      }
    }

    .chin {
      padding-top: 0.5rem;
    }

    .picker {
      display: flex;
      background-color: mat.get-color-from-palette($background, app-bar);
      border-radius: 5px;
      width: 100%;
      height: 44px;
      cursor: pointer;
    }

    .mat-error {
      font-size: 75%;
      margin-top: 0.5rem;
    }

    .mat-hint {
      display: block;
      padding: 0 1rem;
      font-size: 75%;
      margin-top: 0.6666666667em;
    }

    .static-value {
      background-color: mat.get-color-from-palette($background, background);
      border-radius: 5px;
      padding: 0.5rem;
    }
  }

  //wysiwyg editor used where simple rich text is requried (policy)
  .NgxEditor {
    border: none !important;
    border-radius: 0 !important;
  }

  .NgxEditor__MenuBar {
    background-color: mat.get-color-from-palette($background, app-bar);
    border-radius: 5px;
  }

  .NgxEditor__MenuItem {
    cursor: pointer;
  }

  .NgxEditor__Content {
    height: 400px;
    overflow: auto;
    border-bottom: 1px solid mat.get-color-from-palette($foreground, base, 0.4);
  }

  .NgxEditor__Content--Disabled {
    opacity: 0.4;
    border-bottom-style: dashed;
  }
  //end wysisyg editor

  .note {
    background-color: mat.get-color-from-palette($background, background);
    padding: 1rem;
    border-radius: 5px;
    margin-bottom: 1.5rem;
  }

  .option-wrapper {
    padding: 1.5rem;
    background-color: mat.get-color-from-palette($background, app-bar);
    border-radius: 4px;
  }

  .aside-wrapper {
    &.left {
      border-left: 1px solid mat.get-color-from-palette($foreground, base, 0.3);
      padding-left: 1.5rem;
    }

    &.right {
      border-right: 1px solid mat.get-color-from-palette($foreground, base, 0.3);
      padding-right: 1.5rem;
    }
  }

  .preview-wrapper {
    position: sticky;
    top: 1rem;
  }

  .page-index {
    color: mat.get-color-from-palette($foreground, secondary-text);
  }

  .page-count {
    color: mat.get-color-from-palette($foreground, secondary-text);
  }

  .mat-dialog-container {
    background-color: mat.get-color-from-palette($background, background);
  }

  .mat-dialog-warning {
    background-color: mat.get-color-from-palette($background, app-bar);
  }

  .ql-mention-list {
    background-color: mat.get-color-from-palette($background, background);
    list-style: none;
    margin: 0;
    padding: 0;

    .ql-mention-list-item {
      font-size: 1rem;
      padding: 0.5rem;

      &.selected {
        background-color: darken(
          mat.get-color-from-palette($background, background),
          5%
        );
      }
    }
  }

  .mention {
    color: mat.get-color-from-palette($accent);
    background: rgba(mat.get-color-from-palette($accent), 0.2);
    padding: 0.2rem;
    border-radius: 5px;
  }

  .mini-entity {
    .top-right-action {
      opacity: 0;
      transition: 0.2s ease all;
    }

    &:hover {
      .top-right-action {
        opacity: 1;
      }
    }
  }

  .mini-item {
    &:hover {
      .open {
        opacity: 1;
      }
    }

    .image {
      img {
        width: 100px;
        border-radius: 5px;
      }
    }

    .info {
      overflow: hidden;
    }

    h5,
    span {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .open {
      position: absolute;
      bottom: 1rem;
      right: 1rem;
      opacity: 0;
      transition: 0.2s ease all;
    }
  }

  .micro-item {
    .toggle {
      .open {
        opacity: 0;
        transition: 0.2s ease all;
      }

      &:hover {
        .open {
          opacity: 1;
        }
      }
    }
  }

  .breadcrumb-link {
    .image {
      img {
        width: 26px;
        border-radius: 5px;
      }
    }

    .name {
      font-weight: bold;
    }
  }

  .mat-table {
    background-color: transparent;

    tbody {
      tr {
        transition: 0.2s ease background-color;
      }
    }

    .mat-cell {
      padding: 0 5px;
    }
  }

  .row-hover {
    &:hover {
      background-color: rgba(mat.get-color-from-palette($accent), 0.1);
    }
  }

  .row-highlight {
    background-color: rgba(mat.get-color-from-palette($accent), 0.1);
  }

  .qr-wrapper {
    background-color: mat.get-color-from-palette($background, app-bar);
    padding: 1rem;
    text-align: center;
    border-radius: 5px;
    margin-bottom: 1rem;

    .qr-code {
      max-width: 100%;
      width: 200px;
      height: auto;
      box-shadow: 0 3px 6px 0 rgba(black, 0.1);
      border-radius: 5px;
    }
  }

  .version {
    padding: 0.1rem 0.4rem;
    border-radius: 5px;

    &.auth {
      background-color: mat.get-color-from-palette($accent);
      color: white;
    }

    &.app {
      background-color: mat.get-color-from-palette($accent);
      color: white;
    }
  }

  .tooltip-custom {
    font-size: 1rem;
    background: mat.get-color-from-palette($primary) !important;
  }

  .action-chip {
    border-radius: 5px;
    cursor: pointer;

    &:not(.mat-chip-selected) {
      background: none;
      border: 1px solid mat.get-color-from-palette($primary, 100);
      color: mat.get-color-from-palette($primary, 100);
    }

    &.mat-chip-selected {
      border: 1px solid transparent;
    }

    &.mat-standard-chip:focus::after {
      opacity: 0;
    }
  }

  .mat-icon-button {
    &.small {
      width: 1.8rem;
      height: 1.8rem;
      line-height: 1.8rem;

      .mat-icon {
        font-size: 1rem;
        width: 1rem;
        height: 1rem;
        line-height: 1rem;
      }
    }
  }

  .mat-stepper-vertical {
    background: none;
  }

  .mat-step-header {
    &:hover {
      background-color: inherit;
    }
  }

  .mat-progress-bar {
    border-radius: 2px;

    &.mat-accent {
      .mat-progress-bar-buffer {
        background-color: mat.get-color-from-palette($background, app-bar);
      }
    }
  }

  .warn-card {
    border: 1px solid mat.get-color-from-palette($warn);
  }

  .card-prefix {
    background-color: mat.get-color-from-palette($background, app-bar);
  }
}
