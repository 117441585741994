/* For use in src/lib/core/theming/_palette.scss */
$md-pcolor: (
    50 : #e6e5ed,
    100 : #8c8999,
    200 : #9793b6,
    300 : #6d6899,
    400 : #4e4783,
    500 : #2f276d,
    600 : #2a2365,
    700 : #231d5a,
    800 : #1d1750,
    900 : #120e3e,
    A100 : #8278ff,
    A200 : #5245ff,
    A400 : #2312ff,
    A700 : #1200f7,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$md-acolor: (
    50 : #feeee7,
    100 : #fdd4c3,
    200 : #fcb79b,
    300 : #fa9a72,
    400 : #f98454,
    500 : #f86e36,
    600 : #f76630,
    700 : #f65b29,
    800 : #f55122,
    900 : #f33f16,
    A100 : #ffffff,
    A200 : #fff2f0,
    A400 : #ffc7bd,
    A700 : #ffb2a3,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

