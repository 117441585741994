@font-face {
  font-family: 'loyale-icons';
  src:  url('fonts/loyale-icons.eot?5u9lvk');
  src:  url('fonts/loyale-icons.eot?5u9lvk#iefix') format('embedded-opentype'),
    url('fonts/loyale-icons.ttf?5u9lvk') format('truetype'),
    url('fonts/loyale-icons.woff?5u9lvk') format('woff'),
    url('fonts/loyale-icons.svg?5u9lvk#loyale-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="loyale-icon-"], [class*=" loyale-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'loyale-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.loyale-icon-ios:before {
  content: "\e901";
}
.loyale-icon-linux:before {
  content: "\e902";
}
.loyale-icon-mac:before {
  content: "\e903";
}
.loyale-icon-other:before {
  content: "\e904";
}
.loyale-icon-windows:before {
  content: "\e905";
}
.loyale-icon-android:before {
  content: "\e900";
}
.loyale-icon-linkedin:before {
  content: "\e90c";
}
.loyale-icon-instagram:before {
  content: "\e913";
}
.loyale-icon-twitter:before {
  content: "\e914";
}
.loyale-icon-facebook:before {
  content: "\e915";
}
