@import "./styles.material";
@import "./mixins";
@import "./colors";

html,
body {
  font-family: "Roboto", sans-serif;
  background-color: $background;
}

.theme-wrapper {
  position: fixed;
  top: 0;
  width: 100%;
}

code {
  font-family: "Courier New", monospace;
}

.mat-drawer-container {
  flex-grow: 1;
  background-color: $background !important;

  .mat-drawer {
  }

  .mat-drawer-content {
    .toggle-wrapper {
      position: fixed;
      top: 1rem;
      right: 1rem;
      z-index: 2001;
    }
  }
}

.mat-menu-panel {
  min-height: 1px !important;
}

.mat-menu-content:not(:empty) {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.mat-checkbox-layout {
  white-space: inherit !important;
}

.mat-checkbox {
  label {
    margin: 0 !important;
  }
}

.helper {
  font-size: 0.8rem;
  width: 0.8rem !important;
  height: 0.8rem !important;
}

.mat-toolbar {
  .mat-form-field {
    font-size: 0.8rem;
  }

  .mat-icon-button[disabled] {
    color: rgba(255, 255, 255, 0.26) !important;
  }
}

.color-disabled {
  opacity: 0.4;
}

.cursor-pointer {
  cursor: pointer !important;
  outline: none !important;
}

.pointer-events-none {
  pointer-events: none !important;
}

.tr {
  display: table-row;
}

.td {
  display: table-cell;
}

@media print {
  .do-not-print,
  .mat-snack-bar-container {
    display: none !important;
  }

  .app-content {
    width: 100% !important;
  }

  .mat-toolbar {
    color: black !important;
  }

  .mat-dialog-content {
    width: 100%;
    overflow: overlay;
  }

  .cdk-overlay-pane {
    width: 100% !important;
    max-width: 96vw !important;
  }

  .cdk-global-overlay-wrapper {
    align-items: flex-start !important;
  }

  .invoice-print {
    margin-top: 5rem !important;
    margin-left: 3rem !important;
  }
}

.mat-standard-chip {
  height: auto !important;
}

.mat-table-fake {
  width: 100%;

  thead {
    tr {
      th {
        height: 56px;
        font-size: 12px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.54);
        vertical-align: middle;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);

        &:first-of-type {
          padding-left: 24px;
        }

        &:last-of-type {
          padding-right: 24px;
        }
      }
    }
  }

  tbody,
  tfoot {
    .tr,
    tr {
      td {
        height: 48px;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.87);
        vertical-align: middle;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);

        &:first-of-type {
          padding-left: 24px;
        }

        &:last-of-type {
          padding-right: 24px;
        }
      }
    }
  }
}

.vh-25 {
  height: 25vh;
}

.vh-50 {
  height: 50vh;
}

.vh-75 {
  height: 75vh;
}

.vw-25 {
  width: 25vw;
}

.vw-50 {
  width: 50vw;
}

.vw-75 {
  width: 75vw;
}

.emoji-mart-category .emoji-mart-emoji span {
  font-size: 18px !important;
  margin-top: 3px;
}

.emoji-mart-scroll {
  overflow-x: hidden !important;
}

.tags-background {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-image: url(./assets/loyale-tags.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 105%;
}

.ql-html-popupContainer {
  background: white !important;
  padding: 24px;
  border-radius: 4px !important;

  .ql-html-textContainer {
    position: relative !important;
    width: 100% !important;
    height: 100% !important;
    padding: 0 !important;

    .ql-html-popupTitle {
      font: 500 20px/32px Roboto;
    }

    .ql-html-textArea {
      background: white;
      position: relative;
      left: 0;
      width: 100%;
      height: calc(100% - 5rem);
      border: 1px solid #ccc;
      margin-bottom: 1rem;

      .ql-editor {
        background-color: black;

        .ql-syntax {
          color: white;
        }
      }
    }

    .ql-html-buttonGroup {
      display: flex;
      justify-content: flex-end;

      position: relative;
      bottom: 0;
      transform: scale(1);
      left: 0;

      .ql-html-buttonCancel {
        color: rgba(0, 0, 0, 0.87);
        background-color: white;
        padding: 0 1rem;
        height: 36px;
        border-radius: 4px;
        border: none;
        font-weight: 500;
        font-size: 14px;
        display: inline-block;
      }

      .ql-html-buttonCancel + button {
        color: white;
        background-color: #231d5a;
        padding: 0 1rem;
        height: 36px;
        border-radius: 4px;
        border: none;
        font-weight: 500;
        font-size: 14px;
        display: inline-block;
      }
    }
  }
}

.ql-tooltip.ql-editing {
  &[data-mode="image"] {
    left: 10px !important;

    &::before {
      content: "Image URL:";
    }
  }
}

.br-5 {
  border-radius: 5px;
}

.plans {
  table {
    tr {
      td {
        padding: 0.5rem;
      }
    }

    tbody {
      tr {
        td {
          width: 16.66% !important;
          text-align: center;
          border-bottom: 1px solid rgba(black, 0.1);

          &:nth-child(1) {
            text-align: left;
            font-size: 0.8rem;
          }
        }
      }
    }

    tfoot {
      tr {
        td {
          width: 16.66% !important;
          text-align: center;
        }
      }
    }
  }

  .feature-not-available {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    background-color: rgba(black, 0.1);
    display: inline-block;
  }
}

section {
  margin: 0 1.5rem 1.5rem 1.5rem;
}

.overview-box {
  h3 {
    margin-bottom: 0;

    span {
      font-size: 1rem;
    }
  }

  small {
    color: rgba(black, 0.8);
  }
}

.picker-v2 {
  width: 32px;
  height: 32px;
  background-color: rgba(black, 0.1);
  display: block;
  cursor: pointer;
  border-radius: 5px;

  margin-bottom: -7px;

  &.prefix {
    margin-right: 0.5rem;
  }

  &.suffix {
    margin-left: 0.5rem;
  }
}

.loyale-icon-android {
  color: #83e30a;
}

.loyale-icon-ios {
  color: #0f0f0f;
}

.loyale-icon-windows {
  color: #0278cf;
}

.loyale-icon-mac {
  color: #8e8e93;
}

.loyale-icon-linux {
  color: #fac100;
}

.loyale-icon-other {
  color: #f86e36;
}

.color-picker {
  .selected-color {
    border: none !important;
    border-radius: 5px !important;
  }
}

.shimmer {
  background: #f6f7f8;
  background-image: linear-gradient(
    to right,
    #f6f7f8 0%,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  background-repeat: no-repeat;
  background-size: 500px 500px;
  display: inline-block;
  position: relative;

  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderShimmer;
  -webkit-animation-timing-function: linear;
}

@-webkit-keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.vh-100-min {
  min-height: 100vh;
}

.dialog-responsive {
  width: 40%;
}

@media only screen and (max-width: 991px) {
  .dialog-responsive {
    width: 100%;
  }
}

.lock-feature-icon {
  width: 48px !important;
  height: 48px !important;
  font-size: 48px !important;
}

._blockItem_1ajtj_6 {
  font-size: 12px;
}

._blockItem_1ajtj_6:nth-last-child(1) .iconfont::before {
  content: url(assets/notifications_off_black_24dp.svg);
}

.color-green {
  color: $green;
}

.arco-trigger {
  background: white !important;
}

.get-started-point {
  .mat-icon {
    font-size: 2rem;
    width: 2rem;
    height: 2rem;
  }
}

.paragraph {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;

  mat-icon {
    font-size: 32px;
    overflow: visible;
    margin-right: 1.5rem;
  }

  .paragraph-title {
    color: var(--Black, #29282e);
    font-family: Roboto;
    margin-bottom: 4px;
    font-size: 19px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.231px;
  }
}

.touranchor--is-active {
  padding: 15px;
  border-radius: 4px;
}

.ngx-charts-outer {
  width: 100% !important;
  height: auto !important;

  .chart-legend {
    .legend-title {
      font-weight: 400 !important;
    }

    .legend-labels {
      background: none !important;
    }
  }
}

.sticky-wrapper {
  position: sticky;
  top: 5rem;
}

.is-5,
.icon-big {
  font-size: 2rem;
  width: 2rem !important;
  height: 2rem !important;
}

.icon-small {
  font-size: 0.8rem;
  width: 0.8rem !important;
  height: 0.8rem !important;
}

.cta {
  span {
    line-height: 1.2;
  }
}

.cta-light-warning {
  background: #ffa736 !important;
}

.cta-medium-warning {
  background: #e85f16 !important;
}
